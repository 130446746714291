//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-904:_7012,_2120,_888,_3356,_4732,_8488,_5424,_5500;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-904')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-904', "_7012,_2120,_888,_3356,_4732,_8488,_5424,_5500");
        }
      }catch (ex) {
        console.error(ex);
      }