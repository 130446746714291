const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal'];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';

window.Convermax.handleCompareButton = (id) => {
  const compareHeaderButton = window.document.querySelector('a[data-compare-nav]');

  if (compareHeaderButton) {
    const compareIcon = window.document.querySelector(`[cm-data-compare-id="${id}"]`);

    compareIcon.classList.toggle('compare--active');

    const isProductCompared = compareIcon.classList.contains('compare--active');
    const compareCounter = window.document.querySelector('a[data-compare-nav] span');

    // if header doesn't contains counter, add it
    if (!compareCounter) {
      const counter = document.createElement('span');

      counter.classList.add('countPill', 'countPill--positive', 'countPill--alt');

      if (isProductCompared) {
        counter.innerText = 1;
        compareHeaderButton.href += `/${id}`;
      }

      compareHeaderButton.appendChild(counter);
    } else {
      const compareCounterInt = parseInt(compareCounter.innerText);

      if (isProductCompared) {
        compareHeaderButton.href += `/${id}`;
        compareCounter.innerText = compareCounterInt + 1;
      } else {
        compareHeaderButton.href = compareHeaderButton.href.replace(`/${id}`, '');
        compareCounter.innerText = compareCounterInt - 1;
      }

      compareCounter.style.display = compareCounter.innerText === '0' ? 'none' : 'block';
    }

    compareIcon.checked = isProductCompared;
  }
};

function getLocalPreselection(pageType, defaults) {
  const isCategoryPage = pageType === 'category';
  const isBrandPage = pageType === 'brand';
  if (isCategoryPage || isBrandPage) {
    // breadcrumbs selector differs from default
    const breadcrumbs = [...window.document.querySelectorAll('.breadcrumb-label')];
    const isMetaFromZero = breadcrumbs.some(
      (el) => el.querySelector('meta')?.getAttribute('content') === '0',
    );

    const parsedBreadcrumbs = breadcrumbs
      .map((elem) => {
        const text = (elem.textContent || elem.innerText).trim();
        const metaAttr = elem.querySelector('meta')?.getAttribute('content');
        const ignore =
          text.toLowerCase() === 'home' || (isMetaFromZero ? metaAttr === '0' : metaAttr === '1');
        return !ignore && text;
      })
      .filter(Boolean);

    const termFromBreadcrumbs = parsedBreadcrumbs.join('>');
    if (termFromBreadcrumbs) {
      return [
        {
          field: isCategoryPage ? 'category' : 'brand_name',
          term: termFromBreadcrumbs,
          treeLevel: isCategoryPage ? parsedBreadcrumbs.length - 1 : null,
        },
      ];
    }
  }

  return defaults.getter(pageType);
}

export default {
  platform: 'bigcommerce',
  SearchRequestDefaults: {
    pageSize: 32,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
    categorySelectionPageRedirect: true,
  },
  getLocalPreselection,
  page: {
    getPageType: (defaults) =>
      window.location.pathname === categorySelectionPageUrl
        ? 'parts'
        : window.document.querySelector('body.category')
          ? 'category'
          : window.document.querySelector('body.brand')
            ? 'brand'
            : defaults.getter(),
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: { selector: '#cm_results', class: 'products-category' },
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.row.page .products-category',
      template: 'MainContent',
    },
    {
      name: 'SearchHeader',
      sortSelectClass: 'form-select',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: '#faceted-search-container',
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: { selector: 'header .sb-quickSearch', class: 'sb-searchpro' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: '.mheader-top .sb-searchpro',
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'HeaderVehicleWidget_desktop',
      type: 'VehicleWidget',
      location: {
        selector: '#cm_ymm',
        class: 'container',
      },
      columnBreakpoint: 600,
      template: 'fitmentSearch/collapsibleVehicleWidget',
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        selector: '#cm_ymm-mobile',
        class: 'container cm_vehicle-widget__header__mobile',
      },
      columnBreakpoint: 990,
      initCollapsed: true,
      template: 'fitmentSearch/collapsibleVehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'Garage_desktop',
      type: 'Garage',
      location: {
        wrapper: 'li',
        selector: '#cm_garage__desktop',
        class: 'navUser-item cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'Garage_mobile',
      type: 'Garage',
      location: {
        wrapper: 'li',
        selector: '#cm_garage-mobile',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: '.productView-options' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: {
        insertAfter: '.productView .tabs .tab',
        wrapper: 'li',
        class: 'tab',
      },
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: {
        lastChildOf: '.productView .tabs-contents',
        class: 'tab-content',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.row.page',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
      visibleIf: () => window.location.pathname === categorySelectionPageUrl,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '.brandGrid',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
